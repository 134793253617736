import React from "react"
import Svg from "react-inlinesvg"
import {Box} from "@mui/material"

const Icon = ({ ...props }) => {
  const iconClass = props.className
  const src = "/svgs/icons/" + props.name + ".svg"
  const width = props.width
  const height = props.height


  return (
    <Box className="svgWrapper" sx={{display: "flex", alignItems: "center", svg: {
      path: {
        fill: "inherit",
        stroke: "inherit",
      }
    }}}>
      <Svg className={iconClass} src={src} width={width} height={height} />
    </Box>
  )
}

export default Icon
