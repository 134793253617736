const quoteStyles = {
  settings: {
    lazyLoad: false,
    infinite: true,
    dots: false,
    arrows: false,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    adaptiveHeight: false,
    mobileFirst: false,
  },
}

export default quoteStyles