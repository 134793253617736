import React from "react"
import { Button } from "gatsby-theme-material-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box, Typography, useMediaQuery, useTheme, Container } from "@mui/material"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SVG from "react-inlinesvg"

export const MenuFeature = ({ menuItem }) => {


  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const arrowType = mobile ? <SVG src="/svgs/icons/nav-item-arrow.svg" /> : <SVG src="/svgs/icons/nav-item-arrow.svg" />

  const menuFeature = menuItem?.menu_feature?.menuFeature;
  const { showMenuFeature, excerpt, title } = menuFeature;
  const showFeature = showMenuFeature;


  function isUrl(path) {
    return (
      path.indexOf("http://") === 0 ||
      path.indexOf("https://") === 0 ||
      path.indexOf("mailto:") === 0
    );
  }

  const menuLink = item => {
    if (!item)
      return null;

    const path = item.path;

    return (
      isUrl(path) ? (
        <Button variant="text" sx={{ px: { xs: 0, lg: 0 }, }} to={path}><span dangerouslySetInnerHTML={{ __html: item.label }} /></Button>
      ) : (

        <Button variant="text" to={path} endIcon={arrowType} color="primary"
          size="medium" sx={{
            // WebkitAppearance: "none",
            // mozAppearance: "none",
            // appearance: "none",
            px: { xs: 0, lg: 0 },
            py: { xs: 0.5, lg: 0 },
            margin: 0,
            border: 0,
            backgroundColor: "transparent",
            justifyContent: { xs: "flex-start", lg: "flex-start" },
            textAlign: "left",
            width: { xs: "auto", md: "auto" },
            svg: {
              transition: "all 0.2 ease-in-out 0",
            },
            "&:hover": {
              svg: {
                transform: "translateX(2px)",
              }
            }
          }}
        >
          {item.label}
        </Button>
      )
    )
  }

  return (
    <>{showFeature && <Container maxWidth="xl" disableGutters={true} sx={{ px: '0 !important' }}>
      <Box sx={{
        display: "grid",
        gridTemplateColumns: { xs: "1fr", lg: "repeat(7, 1fr)" },
        gridColumnGap: "14px",
        maxWidth: { lg: 433 },
        pr: { lg: 1 },
        transition: { lg: "all 0.2s linear 0s" },
        animation: { lg: "0.2s fadeIn linear 0s" },
        "& @keyframes fadeIn": {
          from: {
            opacity: 0,
            transform: "translateY(1%)",
          },
          to: {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
      }} className="panelItemFeature">
        {menuFeature.images?.length > 0 &&
          <Box sx={{ gridColumn: "1/auto", display: { xs: "none", lg: "block" }, minWidth: { xs: 50, lg: 151 } }} className="panelLeft">
            <GatsbyImage image={getImage(menuFeature.images[1].localFile.childImageSharp.gatsbyImageData)} />
          </Box>
        }
        {(title || excerpt) &&
          <Box sx={{
            gridColumn: { xs: "1/1", lg: "2/8" },
            display: "flex",
            flexDirection: { xs: "column", lg: "column" },
            justifyContent: { xs: "flex-end", lg: "flex-start" },
          }} className="panelRight">
            <Typography variant="h3" sx={{
              fontFamily: "beyond_infinityregular",
              color: "primary.main",
              display: { xs: "none", lg: "block" },
            }}>{title}</Typography>
            <Typography variant="body1" component="div" color="bodyTextColor.main" sx={{ display: { xs: "none", lg: "block" }, }} dangerouslySetInnerHTML={{ __html: excerpt }} />
            {menuLink(menuItem)}
          </Box>
        }
      </Box>
    </Container>}
    </>
  )

}
