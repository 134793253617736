import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Container } from "@mui/material"
import FooterMenu from "../footerMenu/footerMenu"
import PrivacyMessage from "../privacyMessage/privacyMessage"
import NewsletterPopUp from "../newsletterPopUp/newsletterPopUp"

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      footerMenu: wpMenu(slug: { eq: "footer-menu" }) {
        menuItems {
          items: nodes {
            url
            path
            title
            parentId
            menu_feature {
              menuFeature {
                showMenuFeature
                images {
                  sourceUrl
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 720)
                    }
                  }
                }
              }
            }
            childItems {
              subMenu: nodes {
                menu_feature {
                  menuFeature {
                    title
                    showMenuFeature
                    excerpt
                    fieldGroupName
                    images {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(width: 720)
                        }
                      }
                    }
                  }
                }
                menu_icon {
                  menuIcon {
                    showMenuText
                    showMenuIcon
                    selectMenuIcon
                    fieldGroupName
                  }
                }
                title
                url
                target
                label
                path
              }
            }
            label
            menu_icon {
              menuIcon {
                showMenuText
                showMenuIcon
                selectMenuIcon
                fieldGroupName
              }
            }
          }
        }
        slug
        name
      }
    }
  `)

  const footerMenu = data.footerMenu

  return (
    <>
      <Box component="footer" sx={{
        backgroundColor: "tertiary.main",
      }} className="footerWrapper">
        <Container maxWidth="xl">
          <FooterMenu menu={footerMenu} />
        </Container>
      </Box>
      <PrivacyMessage />
      <NewsletterPopUp />
    </>
  )
}

export default Footer
