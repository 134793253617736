import React from "react"
import { Box } from "@mui/material"
import Icon from "../../utils/icon"
import { Button } from "gatsby-theme-material-ui"

const ListMenu = props => {

  const menuItems = props?.menuItem;

  const checkLink = (item, i) => {
    const onlyPath = item.path;

    const menuIcon =
      "menu_icon" in item && item.menu_icon.menuIcon.showMenuIcon === true ? (
        <Icon
          name={item.menu_icon.menuIcon.selectMenuIcon}
          height="1em"

        />
      ) : (
        ""
      )
    const link =
      onlyPath.indexOf("http://") === 0 ||
        onlyPath.indexOf("https://") === 0 ||
        onlyPath.indexOf("mailto:") === 0 ||
        onlyPath.indexOf("tel:") === 0 ? (

        <Button sx={{ px: 0 }} variant="text" size="small" color="secondary" to={onlyPath} aria-label={`Link to ${item.label}`} startIcon={menuIcon}>
          <span dangerouslySetInnerHTML={{ __html: item.menu_icon && item.menu_icon.menuIcon.showMenuText !== null ? item.label : "" }} />
        </Button>

      ) : (

        <Button sx={{ px: 0 }} variant="text" size="small" color="secondary" to={onlyPath} aria-label={`Link to ${item.label}`} startIcon={menuIcon}>
          <span dangerouslySetInnerHTML={{ __html: item.menu_icon && item.menu_icon.menuIcon.showMenuText !== null ? item.label : "" }} />
        </Button>

      )

    return <>{link}</>
  }

  const getSubMenu = () => {
    return menuItems ?
      menuItems.map((menuItem, index) => {
        return menuItem !== null && menuItem !== "undefined" ? (
          <li key={`footer-menu-item-${index}`}>{checkLink(menuItem, index)}</li>
        ) : (
          ""
        )
      })
      : ""
  }

  return (
    <div style={{}}>
      <Box sx={{
        "& ul": {
          margin: 0,
          padding: 0,
          display: "flex",
          flexDirection: props.alignment === true ? "row" : "column",
          color: "white.main",
          "& a": {
            color: "inherit",
            textDecoration: "none",
            borderBottom: "1px solid transparent",
            marginBottom: "calc(1.4 * 0.2rem)",
            paddingBottom: "calc(1.4 * 0.2rem)",
            display: "inline-block",
            "&:hover": {
              cursor: "pointer",
              borderBottom: "1px solid white",
            },
          },
          "& li": {
            marginRight: props.alignment === true ? "1rem" : "0rem",
            display: props.alignment === true ? "flex" : "block",
            justifyContent: props.alignment === true ? "center" : "unset",
            alignItems: props.alignment === true ? "center" : "unset",
          },
          "&.social-menu": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",

            "& li": {
              "& a": {
                "&:hover": {
                  border: "none",
                  "& svg": {
                    transform: "scale(1.1)"
                  }
                },
              },
              marginRight: "calc(1.4 *1rem)",
              "& .svgWrapper": {
                marginRight: "1em",
                "&:last-child": {
                  marginRight: 0,
                },
                "& svg": {
                  fill: "white",
                },
              },
            },
          },
        },
      }} className="list-menu-wrapper">
        <ul className={props.templateName}>{getSubMenu()}</ul>
      </Box>
    </div >
  )
}

export default ListMenu
