import React, { useState } from "react"
import { Box, Container } from "@mui/material"
import MenuPanel from "./menu-panel"
import Icon from "../../utils/icon"
import { Button } from "gatsby-theme-material-ui"
import CloseIcon from '@mui/icons-material/Close'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton } from "gatsby-theme-material-ui"
//=================================
//
// const Menu = React.memo(props => {
const Menu = props => {

  const onClose = props.onClose;
  const open = props.open;
  const children = props.children;
  const menu = props.menu;
  //const name = props.name;

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fill: "#23A3AC", // Brand: Light Blue | Old: "0298B0",
    span: {
      fontWeight: "inherit !important",
    },
    mx: { xs: 0, lg: 0.125 },
    "& .MuiButton-startIcon": {
      mx: 0,
    }

  }

  // parent menu items
  const menuItems = () => {

    return (menu.menuItems.items.filter(i => !i.parentId));
  }

  //remember any open submenus
  const defaultState = {
    panelID: -1, //-1
  }

  const [menuState, updateMenuStatus] = useState(defaultState)
  //
  const renderLink = (item, i) => {
    const itemPath = item.path;

    const menuIcon =
      "menu_icon" in item && item.menu_icon.menuIcon.showMenuIcon === true ? (

        <Icon
          name={item.menu_icon.menuIcon.selectMenuIcon}
          height="15px"
          color="primary.main"
        />
      ) : (
        ""
      )

    function iconz() {
      if (item.menu_icon) {
        return item.menu_icon.menuIcon.showMenuText !== null ? item.label : ""
      } else {
        return item.label
      }
    }



    const link =
      itemPath.indexOf("http://") === 0 ||
        itemPath.indexOf("https://") === 0 ||
        itemPath.indexOf("mailto:") === 0 ||
        itemPath.indexOf("#") === 0 ||
        itemPath.indexOf("tel:") === 0 ? (
        itemPath ? <Button
          variant="text"
          size="medium" to={itemPath}
          color="primary"
          // onMouseEnter={e => optionSelect(e, i)}
          startIcon={iconz()}
          sx={{
            // External Menu Link
            ...buttonStyle,
            //py: { xs: '0 !important', md: '0 !important' },
            //px: { md: 0 },
          }}
        >
          {menuIcon}
        </Button> : <Button
          variant="text"
          size="medium" to={itemPath}
          color="primary"
          onMouseEnter={e => optionSelect(e, i)}
          startIcon={iconz()}
          sx={{
            // Internal Menu Link
            ...buttonStyle,

          }}
        >
          {menuIcon}
        </Button>
      ) : (
        itemPath ? <Button
          variant="text"
          size="medium"
          color="primary"
          to={itemPath}
          // onMouseEnter={e => optionSelect(e, i)}
          onClick={e => optionSelect(e, i)}
          startIcon={iconz()}
          sx={{
            ...buttonStyle,

          }}
        >
          {menuIcon}
        </Button> : <Button
          variant="text"
          size="medium"
          color="primary"
          to={itemPath}
          onMouseEnter={e => optionSelect(e, i)}
          onClick={e => optionSelect(e, i)}
          startIcon={iconz()}
          sx={{
            // display: "flex",
            // alignItems: "center",

            // fontWeight: 500,
            // fill: "#23A3AC", // Brand: Light Blue | Old: "0298B0",
            // span: {
            //   fontWeight: "inherit !important",
            // },

            mx: { xs: 0, lg: 0.125 },
            ...buttonStyle,
            //py: { xs: '0 !important', md: '0 !important' },
            //px: { md: 0 },
          }}
        >
          {menuIcon}
        </Button>
      )

    return <>{link}</>
  }

  //
  const renderItem = (item, key, props, i) => {

    if (
      item?.childItems?.subMenu &&
      item.childItems.subMenu.length > 0
    ) {
      return (
        <>
          <Button {...props} variant="text" size="medium" color="primary" sx={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "flex-start",
            // width: "100%",
            // textAlign: "left",
            // mx: { xs: 0, lg: 0.125 },
            // //py: { xs: '0 !important', md: '0 !important' },
            // //px: { md: 0 },
            // fontWeight: { lg: 500 },
            ...buttonStyle,
          }}

            onClick={e => subMenuSelect(e, i)}
            onMouseEnter={e => subMenuSelect(e, i)}
            onKeyDown={e => subMenuSelect(e, i)}
            endIcon={<ArrowForwardIosIcon sx={{ display: { lg: "none" } }} color="primary" />}>
            {item.label}
          </Button>

        </>
      )
    } else {
      return renderLink(item, i)
    }
  }

  //
  const subMenu = pId => {
    if (pId >= 0) {
      const menu = menuItems()[pId];
      return (menu?.childItems?.subMenu?.length ? menu : null);
    }
    return null;
  }

  // A submenu has closed
  const subMenuClose = (select) => {

    updateMenuStatus({
      ...menuState,
      panelID: -1,
    })
    //document.body.style.overflowY = 'scroll';

    // submenu an item was selected so make sure any subordinates close
    if (select && onClose) {
      onClose(select);
    }
  }


  //A menu option was selected
  const subMenuSelect = (e, i) => {

    const isMenu = subMenu(i) != null;
    // console.log(`Menu sekect ${i} is menu ${isMenu}`)
    updateMenuStatus({
      ...menuState,
      panelID: isMenu ? i : -1,
    })
    if (!isMenu && onClose) {
      // a submenu was selected so tell the parent
      onClose(true);
    }
  }

  //A Link option was selected
  const optionSelect = (e, i) => {
    // console.log(`Option select ${i} is option ${!isMenu}`)
    updateMenuStatus({
      ...menuState,
      panelID: -1,
    })
    closeMenu();
  }

  const closeMenu = () => {
    //document.body.classList.toggle("noScroll")

    if (onClose)
      onClose(true);
  }
  //**** renderer ******** */  
  return (

    <Box component="nav" sx={{
      backgroundColor: { xs: "white.secondary", lg: "revert" },
      width: { xs: "100%", lg: "revert" },
      height: { xs: "100%", lg: "revert" },
      position: { xs: "absolute", lg: "revert" },
      top: { xs: 0, lg: "revert" },
      left: 0,
      zIndex: 100,
      display: "none",
      pb: { xs: 4, lg: 0 },
      overflowY: { xs: "scroll", lg: "revert" },
      "&.active": {
        display: "revert",
        animation: { xs: "0.2s slideInLeft", lg: "unset" },
        height: '100%',
        pb: { xs: 8, sm: 0 },
        "@media only screen and (max-width: 375px)": {
          position: 'fixed',
        },
        "@media only screen and (max-width: 835px)": {
          position: 'fixed',
        },

      },
      "@keyframes slideInLeft": {
        from: {
          transform: "translate3d(-100%, 0, 0)",
          visibility: "visible"
        },
        to: {
          transform: "translate3d(0, 0, 0)"
        },
      },
    }} theme={props.theme} className={open ? "active" : "inactive"}>

      <Container sx={{
        display: { xs: "flex", lg: "none" },
        flexWrap: "wrap",
        justifyContent: "flex-end",
        width: "100%",
        position: { xs: "fixed", lg: "unset" },
        maxHeight: { xs: "min-content" },
        zIndex: 9,
        pt: { xs: 1.6, lg: 1.6 },
        pb: { xs: 0, lg: 1.6 },
      }}>

        <IconButton aria-expanded="true" onClick={(e) => closeMenu(e)} color="primary.main">
          <CloseIcon />
        </IconButton>
      </Container>

      <Container maxWidth="xl" sx={{
        listStyleType: "none",
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: { lg: "inherit" },
        width: { lg: "auto" },
        order: { lg: 2 },
        my: 0,
        px: { lg: 0 },
        mt: { xs: 4.8, lg: 0 },
      }} component="ul">
        {menuItems().map((menuItem, i) => {
          return (
            <Box sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", lg: "flex-end" },
              textAlign: "left",
              px: { xs: 0, lg: 0.25 },
              py: { xs: 0.2, lg: 0 },
              borderTop: { xs: "1px solid transparent", lg: "3px solid transparent" },
              textDecoration: "none",
              width: { xs: "100%", lg: "auto" },
            }} component="li" className="menuItem" key={i}>
              {renderItem(menuItem, i, props, i)}
            </Box>
          )
        }
        )}

      </Container>

      {children}

      <Box className="divider" sx={{
        width: { lg: "100%" },
        position: { lg: "absolute" },
        top: { xs: 4.8, lg: "7.6rem" },
        left: { xs: 0, lg: 0 },
      }}>
        {/* submenu */}
        <MenuPanel
          panelData={subMenu(menuState.panelID)}
          onClose={subMenuClose}
          open={menuState.panelID >= 0}
        />
      </Box>
    </Box>
  )
}

export default Menu
