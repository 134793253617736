import stackedStyles from "../contentSlide/stackedStyle"
import scrolledStyles from "../contentSlide/scrolledStyle"
import quoteStyles from "../quote/quoteStyle"
import heroStyles from "../heroSlide/heroStyle"
import propertyPageStyles from "../property/propertyPageStyle"

const sliderStyles = {
  type: {
    hero: heroStyles,

    propertyPage: propertyPageStyles,

    quote: quoteStyles,

    contentStacked: stackedStyles,

    contentScrolled: scrolledStyles,

  },
}

export default sliderStyles
