// import React from "react"
// import Icon from "../../../utils/icon"

// function NextArrow(props) {
//   const { className, onClick } = props
//   return (
//     <button className={className} onClick={onClick}>
//       <Icon name="slider-right" width="1rem" />
//     </button>
//   )
// }

// function PrevArrow(props) {
//   const { className, onClick } = props
//   return (
//     <button className={className} onClick={onClick}>
//       <Icon name="slider-left" width="1rem" />
//     </button>
//   )
// }

// const propertyPageStyles = {
//   settings: {
//     lazyLoad: true,
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     dots: false,
//     arrows: true,
//     fade: false,
//     autoplay: false,
//     pauseOnHover: true,
//     adaptiveHeight: false,
//     mobileFirst: true,
//     nextArrow: <NextArrow />,
//     prevArrow: <PrevArrow />,
//     responsive: [
//       {
//         breakpoint: 1023,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           fade: true,
//         },
//       },
//     ],
//   },
// }



const propertyPageStyles = {

}

export default propertyPageStyles
