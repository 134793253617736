import React from "react"
import { useMediaQuery, Box, useTheme } from "@mui/material"
import Gallery from "../gridGallery/gridGallery"

const MenuPanelFeature = ({ menu }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))


  const menuFeature = menu?.menu_feature?.menuFeature;
  const { showMenuFeature } = menuFeature;

  const showFeature = showMenuFeature && isMobile;

  if (showFeature && menuFeature?.images.length > 0) {
    return (
      <Box sx={{ overflow: "hidden", width: "150px" }} className="menuPanelFeature">
        <Gallery content={menuFeature} />
      </Box>
    )
  } else {
    return null;
  }
}

export default MenuPanelFeature
