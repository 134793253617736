import overStyles from './overStyle';
import underStyles from './underStyle';


const sliderStyles = {

    type: {

        imageGridOver: overStyles,

        imageGridUnder: underStyles,

    },

};

export default sliderStyles;