import React from "react"
import { Button } from "gatsby-theme-material-ui"
import { Box, useMediaQuery, useTheme } from "@mui/material"
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SVG from "react-inlinesvg"

const MenuPanelItem = ({ menuItem }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const arrowType = mobile ? <SVG src="/svgs/icons/nav-item-arrow.svg" /> : <SVG src="/svgs/icons/nav-item-arrow.svg" />

  function isUrl(path) {
    return (
      path.indexOf("http://") === 0 ||
      path.indexOf("https://") === 0 ||
      path.indexOf("mailto:") === 0
    );
  }

  const menuLink = item => {
    if (!item)
      return null;

    const path = item.path;

    return (
      isUrl(path) ? (
        <Button variant="text" size="medium" to={path}><span dangerouslySetInnerHTML={{ __html: item.label }} /></Button>
      ) : (

        <Button variant="text" to={path} endIcon={arrowType}
          size="medium" color="primary" sx={{
            // WebkitAppearance: "none",
            // mozAppearance: "none",
            // appearance: "none",
            px: { xs: 0, lg: 0 },
            py: { xs: 0.5, lg: 0.25 },
            margin: 0,
            border: 0,
            backgroundColor: "transparent",
            justifyContent: { xs: "space-between", lg: "flex-start" },
            textAlign: "left",
            width: { xs: "auto", md: "auto" },

            svg: {
              transition: "all 0.2 ease-in-out 0",
            },
            "&:hover": {
              svg: {
                transform: "translateX(2px)",
              }
            }
          }}
        >
          <span sx={{ color: "bodyTextColor.main" }} dangerouslySetInnerHTML={{ __html: item.label }} >
          </span>
        </Button>


      )
    )
  }

  return (
    <Box sx={{
      display: "flex",
      alignItems: "center",
      alignContent: "space-between",
    }} className="panelItemLink" >
      {menuLink(menuItem)}
    </Box>
  )
}

export default MenuPanelItem
