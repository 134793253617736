import React from "react"
import { Box, useTheme, Container } from "@mui/material"
import MenuPanelItem from "./menu-panel-item"
import MenuPanelFeature from "./menu-panel-feature"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "gatsby-theme-material-ui"
import { MenuFeature } from "../menu/menuFeature"

const MenuPanel = props => {
  const theme = useTheme()
  const menu = props?.panelData;
  const menuItems = menu?.childItems?.subMenu;
  const onClose = props.onClose;
  const pClass = props.className;

  const optionClick = (e, i) => {
    if (onClose) {
      onClose(true);
    }
  }

  const leave = (e, i) => {
    if (onClose) {
      onClose(false);
    }
  }

  if (!menu)
    return null;

  const renderMenuItems = () => {
    let featureArea = [];
    let listArea = [];
    for (var i = 0; i < menuItems.length; i++) {
      !menuItems[i].menu_feature?.menuFeature.showMenuFeature ? listArea.push(menuItems[i]) : featureArea.push(menuItems[i]);
    }
    return <>
      <Box
        sx={{
          gridColumn: { lg: "span 16" },
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          textAlign: "left",
          px: { xs: 0, lg: 0 },
          py: { xs: 0, lg: 0.5 },
          //borderTop: "1px solid transparent",
          // borderBottom: { xs: `1px solid ${theme.palette.grey.lightGrey}`, lg: 0 },
        }}
        component="li"
        className="panelMenuItemFeature"
        onClick={e => optionClick(e, -1)}
      >
        {
          featureArea?.map((menuItem, i) => {
            return (<MenuFeature
              key={`${menuItem}-${i}`}
              menuItem={menuItem}
            />)
          })
        }</Box>
      <Box
        sx={{
          gridColumn: { lg: "span 7" },
          display: "block",
          textAlign: "left",
          px: { xs: 0, lg: 0 },
          py: { xs: 0, lg: 0.5 },
          pl: { lg: 4 },
          borderTop: "1px solid transparent",
          //borderBottom: { xs: `1px solid ${theme.palette.grey.lightGrey}`, lg: 0 },
        }}
        component="li"
        className="panelMenuItem"
        onClick={e => optionClick(e, -1)}
      >
        {listArea.map((menuItem, i) => {
          return (<MenuPanelItem
            key={`${menuItem}-${i}`}
            menuItem={menuItem}
          />)
        })}
      </Box>
    </>

  }
  return (

    <Box sx={{
      position: "absolute",
      top: 0,
      left: 0,
      display: "block",
      order: 1,
      width: "100%",
      zIndex: 9,
      backgroundColor: "white.secondary",
      height: { xs: "100%", lg: "max-content" },
      minHeight: { lg: "90px" },
      transition: { lg: "width 0.2s linear 0s" },
      margin: { lg: "0 auto" },
      maxHeight: { xs: "min-content", lg: "unset" },
      minHeight: { xs: "max-content", lg: "max-content" },
    }} className={`panelContainer ${pClass} active`} onMouseLeave={e => leave(e, -1)}>

      <Container maxWidth="xl" sx={{
        display: { xs: "flex", lg: "none" },
        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",
        pt: { xs: 1.6, lg: 1.6 },
        pb: { xs: 0, lg: 1.6 },
      }}>
        <IconButton onClick={e => leave(e, -1)} color="primary.main">
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton onClick={e => leave(e, -1)} color="primary.main">
          <CloseIcon />
        </IconButton>
      </Container>

      <Container maxWidth="xl" sx={{
        display: "flex",
        zIndex: 2,
        pt: { xs: 1, lg: 0 },
      }} className="panelContent">
        <MenuPanelFeature
          menu={menu}
          key={menu.slug} />
        <Box sx={{
          position: "relative",
          display: "flex",
          width: "100%",
          animation: {
            xs: "0.2s slideInLeft linear 0s", lg: "unset"
          },
          "@keyframes slideInLeft": {
            from: {
              transform: "translate3d(-100%, 0, 0)",
              visibility: "visible"
            },
            to: {
              transform: "translate3d(0, 0, 0)"
            },
          },

        }
        } className="panelMenuContainer">
          <Box sx={{
            display: { xs: "flex", lg: "grid" },
            gridColumnGap: "14px",
            gridTemplateColumns: { lg: "repeat(24, 1fr)" },
            flexDirection: "column",
            flexWrap: "wrap",
            listStyleType: "none",
            px: { xs: 0.5, lg: 0 },
            py: { xs: 0, lg: 4 },
            margin: { xs: "0" },
            width: "100%",
            boxSizing: { lg: "border-box" },
          }} component="ul" className="panelMenuList">
            {renderMenuItems()}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default MenuPanel
