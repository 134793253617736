import React, { useReducer, createContext, useEffect } from "react"

export const WishListContext = createContext(null)

export default ({ children }) => {
  //get locally stored state
  const getStoredComponentState = () => {
    const storedState = typeof window !== 'undefined' && window.localStorage.wishList
    return storedState ? JSON.parse(storedState) : []
  }

  const intialState = getStoredComponentState()

  const reducer = (wishList, action) => {
    let updateValue = []
    switch (action.type) {
      case "REMOVE_ITEM":
        const updateWishList = wishList
        updateValue = updateWishList.filter(
          x => x !== action.updateRef.toString()
        )
        return updateValue
      case "ADD_ITEM":
        updateValue = [...wishList, action.updateRef.toString()]
        return updateValue
      default:
        return updateValue
    }
  }

  const [wishList, dispatch] = useReducer(reducer, intialState)

  useEffect(() => {
    return typeof window !== 'undefined' && window.localStorage.setItem("wishList", JSON.stringify(wishList))
  }, [wishList])

  const wish = {
    wishList: wishList,
    handleWishlist: dispatch,
  }

  return (
    <WishListContext.Provider value={wish}>{children}</WishListContext.Provider>
  )
}
