import React from "react"
import { Box, Alert, useTheme } from '@mui/material';

const PrivacyMessage = () => {
    const theme = useTheme()
    const storage = typeof window !== 'undefined' && window.localStorage ? window.localStorage : null

    function getSavedState() {
        if (storage) {
            const show = storage.privacy ? JSON.parse(storage.privacy) : true
            return (show)
        }
        else
            return false
    }

    const [showMessage, setShowMessage] = React.useState(getSavedState());

    function handleClick(e) {

        setShowMessage(false);
        typeof window !== 'undefined' && window.localStorage.setItem('privacy', false);
    }

    return (
        <Box sx={{ display: { xs: "none", md: showMessage ? "block" : "none" } }}>
            <Alert
                onClose={e => handleClick(e)}
                severity="info"
                onClick={(e) => handleClick(e)}
                sx={{
                    color: theme.palette.white.main,
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 0,
                    "& a": {
                        color: "inherit"
                    }
                }}
            >
                We use cookies on our website to make your experience better and to help us monitor and improve our customer service. By continuing we will assume that you are happy to receive all cookies. You can manage the use of cookies through your browser. Read how we use cookies on our <a href="/privacy">Privacy Policy</a> page.
            </Alert>
        </Box >
    );
}

export default PrivacyMessage