const mq = {
  breakPoints: {
    xl: 1460,
    lg: 1280,
    md: 1024,
    sm: 768,
    xs: 576,
  },

  xl: "screen and (min-width:1460px)",
  lg: "screen and (min-width:1280px)",
  md: "screen and (min-width:1024px)",
  sm: "screen and (min-width:768px)",
  xs: "screen and (min-width:576px)",

  minWidths: {
    xl: "@media screen and (min-width:1460px)",
    lg: "@media screen and (min-width:1280px)",
    md: "@media screen and (min-width:1024px)",
    sm: "@media screen and (min-width:768px)",
    xs: "@media screen and (min-width:576px)",
  },

  maxWidths: {
    xl: "@media screen and (max-width:1460px)",
    lg: "@media screen and (max-width:1280px)",
    md: "@media screen and (max-width:1024px)",
    sm: "@media screen and (max-width:768px)",
    xs: "@media screen and (max-width:576px)",
  },
}

export default mq
