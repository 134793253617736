import React from "react"
import PropTypes from "prop-types"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      />
      <Header />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
