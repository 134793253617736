import { createTheme } from "@mui/material"
import mq from "../theme/mediaQueries"
import fonts from "../theme/fonts"
import containers from "../theme/containers"
import containerCss from "../theme/containers.css"
import buttonStyles from "../theme/components/button/buttonStyle"
import buttons from "../theme/buttons"
import sliderStyles from "../theme/components/slider/sliderStyle"
import gridBlockStyles from "../theme/components/gridBlock/gridBlockStyle"
import quoteStyles from "../theme/components/quote/quoteStyle"
import header from "../theme/components/header/headerStyles"
import propertyTileStyles from "../theme/components/search/propertyTileStyle"
import propertyPageStyles from "../theme/components/property/propertyPageStyle"
import heroStyles from "../theme/components/heroSlide/heroStyle"
import formElementStyles from "../theme/components/formElements/formElementSyles"
import wrappersContainers from "../theme/wrappersContainers"
import formElements from "../theme/formElements"

const coreTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
})
const baseTheme = createTheme({
  spacing: (factor) => `${1.4 * factor}rem`, // (Bootstrap strategy)
  // Unfortuently the palette hasn't been co-ordinated with the cms data so it can't be refactored to make more sense.
  breakpoints: coreTheme.breakpoints,
  palette: {
    primary: {
      main: "#23A3AC", // Brand: Light Blue | Old: "0298B0",
      primaryRGB: "2, 152, 176",
      light: "#02C1DE"
    },
    secondary: {
      main: "#F98048", // Brand: Orange | Old: "BE4E1C",
      secondaryRGBA: "190, 78, 28",
      light: "#F9804880"
    },
    tertiary: {
      main: "#0A4088" // Brand: Dark Blue | Old: 0A4088,
    },
    blacks: {
      MuiCssBaseline: "#0A4088", // Brand: Dark Blue | Old: 333333,
      secondary: "#383535",
      // tertiary: "#484848",
    },
    white: {
      main: "#ffffff",
      secondary: "#F2F1EC",
      tertiary: "#F8F8F7",
      quarternary: "#EDECE5",
      quinary: "#DEDDD6",
    },
    blue: {
      main: "#006C7B",
      secondary: "#23A3AC",
    },
    sand: {
      main: "#F5EACE", // Brand: Sand
      light: "#FCF8EE"
    },
    grey: {
      main: "#D3D3D3",
      lightGrey: "#F8F8F7",
      darkGrey: "#484848",
    },
    lightGrey: {
      main: "#F5EACE", // Brand: Sand | Old: F8F8F7
    },
    mediumGrey: {
      main: "#D3D3D3",
    },
    error: {
      main: "#F98048",
    },
    info: {
      main: "#23A3AC",
    },
    bodyTextColor: {
      main: "#0A4088" // Brand: Dark Blue | Old: 484848
    },
    deepCyan: {
      main: "#006C7B"
    },
    inputOutlinePrimary: "#ffffff"
  },
  typography: {
    fontFamily: [
      'p22-mackinac-pro',
      'serif',
    ].join(','),
    backgroundColor: "#fafafa!important",
    h1: {
      fontSize: "44px",
      lineHeight: "56px",
    },
    h2: {
      fontSize: "39.2px",
      lineHeight: "43.12px",
    },
    h3: {
      fontSize: "44px",
      lineHeight: "56px",
      [coreTheme.breakpoints.up('md')]: {
        fontSize: "28px",
        lineHeight: "30.8px",
      },
    },
    h4: {
      fontSize: "26.88px",
      lineHeight: "1.1",
    },
    h5: {
      fontSize: "24.64px",
      lineHeight: "27.104px",
    },
    h6: {
      fontSize: "23.98px",
      lineHeight: "26.378px",
    },
    body1: {
      fontFamily: "'p22-mackinac-pro', serif",
      fontSize: "16px",
      lineHeight: "27px",
      fontWeight: 400,
      // "& > span": {
      //   color: "inherit !important",
      //   fontWeight: "inherit !important",
      // },
    },
    body2: {
      fontFamily: "p22-mackinac-pro, serif",
      fontSize: "16.128px",
      lineHeight: "17.7408px",
    },
    textlink: {
      fontFamily: "p22-mackinac-pro, serif",
      fontSize: "16px",
      lineHeight: "22.88px",
    },
    subtitle1: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "12.80px",
      textTransform: "uppercase",
      letterSpacing: "1.5px",
      lineHeight: "22.88px",
      maxWidth: 320,
      marginBottom: "8px",
    },
    subtitle2: {
      fontFamily: "beyond_infinityregular, sans-serif",
      fontSize: "39.2px",
      lineHeight: "43.12px",
      fontWeight: 400,
      marginBottom: "8px",
    },
    subtitle3: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "12.80px",
      textTransform: "uppercase",
      letterSpacing: "1.5px",
      lineHeight: "22.88px",
      fontWeight: 400,
      marginBottom: "8px",
    },
    caption: {
      fontSize: "12.80px",
      lineHeight: "18.30px",
    },
  }
})

const theme = createTheme({
  mq: mq,
  fonts: fonts,
  containers: containers,
  containerCss: containerCss,
  button: buttonStyles,
  buttons: buttons,
  heroStyle: heroStyles,
  slider: sliderStyles,
  gridBlock: gridBlockStyles,
  quote: quoteStyles,
  header: header,
  property: propertyPageStyles,
  propertyTile: propertyTileStyles,
  formElement: formElementStyles,
  wrappersContainers: wrappersContainers,
  formElements: formElements,
  breakpoints: coreTheme.breakpoints,
  spacing: baseTheme.spacing,
  typography: { ...baseTheme.typography },
  shape: {
    borderRadius: 4,
  },
  palette: {
    ...baseTheme.palette,
    review: {
      yes: "rgb(255, 222, 0)",
      no: baseTheme.palette.grey.main,
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {

        },
        maxWidthLg: {
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        },
        maxWidthXl: {

          [baseTheme.breakpoints.down('sm')]: {
            paddingLeft: "1.2rem",
            paddingRight: "1.2rem",
          },
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        }
      },
    },
    // Range Slider
    MuiSlider: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.primary.main,
          height: "2px",
          padding: "15px 0",
        },
        track: {
          border: "none",
          height: "2px",
        },
        valueLabel: {
          fontSize: 12,
          fontWeight: "normal",
          top: -9,
          backgroundColor: baseTheme.palette.primary.main,
          width: "32px",
          height: "32px",
          borderRadius: "100vw",
          "&:before": {
            bottom: "6px",
            width: "18px",
            height: "18px",
            zIndex: -1,
          },
        },
        valueLabelLabel: {
          color: "white",
        },
        "& .MuiSlider-thumb": {
          height: 28,
          width: 28,
          backgroundColor: "#fff",

          "&:focus, &:hover, &.Mui-active": {
            boxShadow:
              "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
          },
        },
        "& .MuiSlider-rail": {
          opacity: 0.5,
          backgroundColor: baseTheme.palette.primary.main,
        },
        "& .MuiSlider-mark": {
          backgroundColor: baseTheme.palette.primary.main,
          height: 8,
          width: 1,
          "&.MuiSlider-markActive": {
            opacity: 1,
            backgroundColor: "currentColor",
          },
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {

        },
      },
    },
    // Inputs
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "4px",
          width: "100%",
          fontSize: "inherit",
          lineHeight: "inherit",
          WebkitAppearance: "none",
          mozAppearance: "none",
          appearance: "none",
          position: "relative",
          input: {
            "&::placeholder": {
              fontFamily: "p22-mackinac-pro, serif",
            },
          },
          "&.Mui-focused": {
            fieldset: {
              borderColor: `${baseTheme.palette.primary.main}`,
            },
          },
        },
        inputSizeSmall: {
          fontSize: "0.8rem",
          paddingTop: "1rem !important",
          paddingBottom: "1rem !important",
        },
      },
    },
    MuiFilledInput: {

      styleOverrides: {
        root: {
          textTransform: "unset",
          fontSize: 16,
          borderRadius: 0,
          border: "1px solid #D3D3D3",
          color: "#484848",
          backgroundColor: "white",
          boxShadow: "unset",
        },
        colorSecondary: {

        },
      },
    },
    // MuiTextField: {
    //   styleOverrides: {
    //     "& .MuiOutlinedInput-root": {

    //       border: "1px solid #D3D3D3",

    //     },
    //     colorSecondary: {
    //       color: "#484848",
    //     },
    //   },
    // },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: baseTheme.palette.primary.main,
          },
        },
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.primary.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: baseTheme.palette.grey.main,
          },
        },
        colorSecondary: {
          color: baseTheme.palette.bodyTextColor.main, // Old: 484848,
          input: {
            color: baseTheme.palette.bodyTextColor.main,
            "&::placeholder": {
              color: baseTheme.palette.bodyTextColor.main + "!important",
            },
          },
          fieldset: {
            border: "1px solid #D3D3D3",
          },
        },
        colorPrimary: {
          backgroundColor: "transparent",// baseTheme.palette.primary.main,
          input: {
            color: baseTheme.palette.white.main,
          },
          fieldset: {
            border: "1px solid #ffffff",
          },
          "&.Mui-focused": {
            fieldset: {
              color: baseTheme.palette.white.main,
              border: `2px solid ${baseTheme.palette.white.main} !important`,
            },
          },
        },
        notchedOutline: {
          fieldset: {
            color: "#484848",

          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {

        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent"
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          padding: 0,
          border: "1px solid rgb(211, 211, 211)",
          borderRadius: "4px",
          backgroundColor: "white",
          lineHeight: "inherit",
          appearance: "none",
          position: "relative",
          width: "fit-content",
          minWidth: "fit-content",
        },
        groupedText: {
          color: baseTheme.palette.tertiary.main,
          paddingTop: "1rem",
          paddingBottom: "1rem",
          fontSize: "0.8rem",
          "&.active": {
            color: baseTheme.palette.secondary.main,
          },
        },
        groupedOutlinedHorizontal: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
      styleOverrides: {
        sizeSmall: {
          fontSize: 14,
          padding: "0rem 0.5rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "unset",
          fontSize: 16,
          borderRadius: 4,
          padding: "1rem 1.4rem",
        },
        sizeSmall: {
          fontSize: 14,
          padding: "0rem 0rem",
        },
        sizeMedium: {
          fontSize: 16,
          padding: "0.4rem 1rem",
        },
        sizeLarge: {
          maxHeight: 56,
        },
        mainNav: {
          px: { lg: 0 },
          py: { lg: 0 },
        },
        text: {
          borderRadius: 0,
        },
        textPrimary: {
          color: baseTheme.palette.bodyTextColor.main,
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "transparent",
            color: baseTheme.palette.primary.main,
          },
        },
        textSecondary: {
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "transparent",
          },
        },
        textTertiary: {
          color: baseTheme.palette.primary.main,
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "transparent",
          },
        },
        containedPrimary: {
          border: `1px solid ${baseTheme.palette.primary.main}`,
          color: "white",
          backgroundColor: baseTheme.palette.primary.main,
          fontStyle: "italic",
          "&:hover": {
            backgroundColor: "#23A3AC",
            color: "white",
            border: "1px solid #23A3AC",
          },
        },
        containedSecondary: {
          border: `1px solid ${baseTheme.palette.secondary.main}`,
          color: "white",
          backgroundColor: baseTheme.palette.secondary.main,
          fontStyle: "italic",
          "&:hover": {
            backgroundColor: "#F98048cc",
            color: "white",
            border: "1px solid #F98048cc",
          },
        },
        outlinedSecondary: {
          display: "inline-block",
          textDecoration: "none",
          transition: "all 0.3s ease 0s",
          cursor: "pointer",
          border: `1px solid ${baseTheme.palette.secondary.main}`,
          color: baseTheme.palette.secondary.main,
          backgroundColor: "transparent",
          fontStyle: "italic",
          padding: "0.7rem 1.4rem",
          "&:hover": {
            backgroundColor: baseTheme.palette.secondary.main,
            color: baseTheme.palette.white.main,
            border: `1px solid ${baseTheme.palette.secondary.main}`,
          },
        },
        outlinedTertiary: {
          backgroundColor: "white",
          color: "#484848",
          border: "1px solid #D3D3D3",
          padding: "1rem",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          "&.Mui-focused": {
            fieldset: {
              borderColor: `transparent !important`,
            },

          },
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          labelPlacementStart: {
            justifyContent: 'space-between',
            marginRight: 'invalidate'
          }
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          standardWarning: {
            backgroundColor: baseTheme.palette.secondary.main,
            color: "white",
            zIndex: "99999999",
            position: "fixed",
            bottom: "0",
            "& a": {
              color: "white",
            }
          },
        },
      },
    },
  },
  props: {
    MuiSelect: {
      variant: "outlined",
      notched: "false",
      native: true
    },
    MuiTextField: {
      variant: "outlined",
      notched: "false",
    },
  },
});

export default theme

export const chcTheme = theme;