// import mq from "../../mediaQueries"

const overStyles = {
  wrapper: {
    // ".tileWrapper": {
    //   ".gatsby-image-wrapper": {
    //     transition: "all 0.3s ease",
    //     // minHeight: "50vh",
    //     [mq.minWidths.sm]: {
    //       minHeight: "300px",
    //     }
    //   },
    //   [mq.minWidths.lg]: {
    //     "a:hover .gatsby-image-wrapper": {
    //       transform: "scale(1.01)",
    //     },
    //   },
    // },
  },
}

export default overStyles
