import React from "react"
import { graphql } from "gatsby"
import { Box, useTheme, useMediaQuery } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Parallax } from "react-scroll-parallax"


const GridGallery = (({ parallaxController, content, makeParallax }) => {
  const images = [...content.images]
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  if (content.primaryImage) {
    images.push(content.primaryImage)
  }

  const numRows = images.length

  return (
    <Box sx={{ height: "100%" }}>
      {images &&
        // Desktop
        <Box sx={{
          height: "100%",
          maxHeight: { xs: "max-content", sm: "40vh", md: "65vh" },
          overflow: { xs: 'hidden', md: 'unset' },
          display: "grid",
          gridTemplateRows: { xs: "auto", sm: "repeat(12, 1fr)" },
          gridTemplateColumns: { xs: 'auto', md: "repeat(12, 1fr)" },
          "&.gallery-1": {
            "& .item-0": {
              gridArea: "1/1/13/13",
            },
          },
          "&.gallery-2": {
            "& .item-0": {
              gridArea: { xs: "1/1/6/5", md: "1/1/13/5" },
              opacity: 0.6,
              "& .gatsby-image-wrapper": {
                height: "100%", width: "100%"
              }
            },
            "& .item-1": {
              gridArea: { xs: "2/2/9/13", sm: "1/2/7/12", md: "3/2/13/12" },
            },
          },
          "&.gallery-3": {
            "& .item-0": {
              gridArea: { xs: '1/1/6/9', md: '1/1/10/9' },
              opacity: { xs: 0.4, md: 0.6 }

            },
            "& .item-1": {
              gridArea: { xs: '2/13/11/8', md: '2/13/11/8' },
            },
            "& .item-2": {
              gridArea: { xs: '2/2/13/12', md: '3/2/13/12' },
            },
          },
          "&.gallery-4": {
            "& .item-0": {
              gridArea: { xs: "1/6/5/12", sm: "4/6/12/12" },
              opacity: 0.6,
            },
            "& .item-1": {
              gridArea: { xs: "2/7/5/13", sm: "2/7/8/13" },
            },
            "& .item-2": {
              gridArea: { xs: "2/2/4/7", sm: "2/2/9/7" },
              opacity: 0.6,
            },
            "& .item-3": {
              gridArea: { xs: "2/1/7/11", sm: "3/1/13/11" },
            },
          },
        }} className={`gallery-` + numRows} key={0}>
          {images.map((image, i) => {
            const speed = 2 * (images.length - i)

            return (
              <Parallax speed={makeParallax === "yes" ? speed : 0} key={i} className={`item-` + i}>
                {image.mobile && isMobile && <GatsbyImage style={{}} image={image.mobile ? getImage(image.mobile?.childImageSharp.gatsbyImageData) : getImage(image?.localFile.childImageSharp.gatsbyImageData)} alt={image?.altText} />}
                {image.desktop && !isMobile && <GatsbyImage image={image.desktop ? getImage(image.desktop?.childImageSharp.gatsbyImageData) : getImage(image?.localFile.childImageSharp.gatsbyImageData)} alt={image?.altText} />}
              </Parallax>
            )
          })}
        </Box>
      }
    </Box >
  )
})

export default GridGallery

export const query = graphql`
  fragment GridGalleyFragment on WpMediaItem {
    mobile: localFile {
      childImageSharp {
         gatsbyImageData(
          placeholder: DOMINANT_COLOR
          layout: CONSTRAINED
          width: 400
        )
      }
    }
    desktop: localFile {
      childImageSharp {
         gatsbyImageData(
          placeholder: DOMINANT_COLOR
          height: 600
        )
      }
    }
    sourceUrl
    
    altText
  }
  fragment GridGalleryPrimaryImageFragment on WpMediaItem {
    mobile: localFile {
      childImageSharp {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          layout: CONSTRAINED
          width: 400,
        )
      }
    }
    desktop: localFile {
      childImageSharp {
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          layout: CONSTRAINED
          width: 700,
        )
      }
    }
    sourceUrl
    
    altText
  }
`

