const underStyles = {
  wrapper: {
    // ".tileWrapper": {
    //   ".tileHeading": {
    //     display: "inline-block",
    //     marginBottom: 0,
    //     "&:after": {
    //       content: "''",
    //       width: "0px",
    //       height: "1px",
    //       display: "block",
    //       background: "#F98048",
    //       transition: "all 0.3s ease",
    //       marginTop: "2px",
    //     },
    //   },
    //   "a:hover .tileHeading:after": {
    //     width: "100%",
    //   },
    // },
  },
}

export default underStyles
