import React, { useEffect, useState, useRef, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { useFormik } from "formik"
// import { string, object } from "yup"
import {
  useMediaQuery,
  Grid,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "gatsby-theme-material-ui"
import { Helmet } from "react-helmet"
import illustration from "../../../static/images/fern.png"


const NewsletterPopUp = props => {
  const isMobile = useMediaQuery("(max-width:600px)")
  const theme = useTheme()
  const storage =
    typeof window !== "undefined" && window.localStorage
      ? window.localStorage
      : null

  const formRef = useRef()

  const [showNewsletter, setShowNewsletter] = useState(false)
  const [showSubmit, setShowSubmit] = useState(false)

  const cmFormValues = {
    firstName: "cm-f-ikikduu",
    lastName: "cm-f-ikikhll",
    email: "email",
  }

  // const [formValues, setFormValues] = useState({
  //   [cmFormValues.firstName]: "",
  //   [cmFormValues.lastName]: "",
  //   [cmFormValues.email]: "",
  // })

  function closePopup() {
    typeof window !== "undefined" &&
      window.localStorage.setItem("newsletter", false)

    const timer = setTimeout(() => {
      setShowNewsletter(false)
    }, 1000)

    return () => clearTimeout(timer)
  }

  const handleClose = () => {
    typeof window !== "undefined" &&
      window.localStorage.setItem("newsletter", false)

    setShowNewsletter(false);
  };

  const getSavedState = useCallback(() => {
    if (storage) {
      const show = storage.newsletter ? JSON.parse(storage.newsletter) : true
      return show
    } else return false
  }, [storage])

  const popupInterval = useCallback(() => {
    const timer = setTimeout(() => {
      setShowNewsletter(getSavedState())
    }, 15000)
    return () => clearTimeout(timer)
  }, [setShowNewsletter, getSavedState])

  useEffect(() => {
    popupInterval()
  }, [popupInterval])


  const data = useStaticQuery(graphql`
    query NewsletterPopUpQuery {
      wp {
        companyDetails {
          companyDetails {
            newsletterPopup {
              showPopup
              text
              title
              policyLink {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const devForm =
    "5B5E7037DA78A748374AD499497E309E8463DB26A7C5C3AC8EE3DFCBDE66824833697047059BB748F4E9171315D505D66A375C2714B6A88E9408879EB21CB3C9"
  const formKey = process.env.GATSBY_CM_NEWS_POPUP_FORMID ?? devForm

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const submission = await formRef.current.submit();
    closePopup();
  }

  const inputProps = {
    className: 'js-cm-email-input'
  }

  return (
    <Box>
      {data.wp.companyDetails.companyDetails.newsletterPopup.showPopup === true && (
        <Box>
          <Helmet>
            <script
              type="text/javascript"
              async
              src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
            ></script>
          </Helmet>

          <Dialog
            open={showNewsletter}
            onClose={e => closePopup()}
            aria-labelledby="form-dialog-title"
            sx={{
              maxWidth: { xs: '80vw', sm: '50vw' },
              mx: 'auto'
            }}
          >
            <form onSubmit={handleSubmit} ref={formRef} className="js-cm-form" id="subForm" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id={formKey}>
              <Box sx={{
                backgroundSize: { xs: "50px", md: "contain" },
                backgroundRepeat: "no-repeat",
                backgroundPosition: "125% 50px",
                p: { xs: 0, sm: 1, md: 2 },
                pt: { xs: 1, sm: 1, md: 2.5 },
                pb: { xs: 0.5, sm: 1, md: 2.5 },
                backgroundImage: `url(${illustration})`,
              }}
              >
                {showSubmit && (<Box display="flex" justifyContent="flex-end" alignItems="center" width={1} >
                  <IconButton disableFocusRipple={true} disableRipple={true} aria-label="close" onClick={e => handleClose()}>
                    <CloseIcon color="primary" />
                  </IconButton>
                </Box>)}

                {data.wp.companyDetails.companyDetails.newsletterPopup.title && !showSubmit && (
                  <DialogTitle id="form-dialog-title" disableTypography="true" component="div" sx={{
                    my: { xs: 0, sm: 0, md: 'unset' },
                    p: { xs: 0.8, sm: 0, md: 'unset' },
                    pt: { xs: 0, md: 'unset' },
                    pb: { xs: 0, md: 'unset' },

                  }}>
                    <Box display="flex" justifyContent="space-between" alignItems={!isMobile ? "center" : "flex-start"} width={1} >
                      <Typography
                        variant={!isMobile ? "subtitle2" : "subtitle2"}
                        component="h3"
                        color="primary"
                        sx={{
                          fontSize: { xs: theme.spacing(1.5), md: theme.spacing(1.5) },
                          lineHeight: { xs: `${theme.spacing(1.3)} !important`, md: theme.spacing(1.5) },
                          my: { xs: '0 !important', md: 'unset' },
                          py: { xs: '0 !important', md: 'unset' },

                        }}
                      >
                        {data.wp.companyDetails.companyDetails.newsletterPopup.title}
                      </Typography>
                      <IconButton disableFocusRipple={true} disableRipple={true} aria-label="close" onClick={e => handleClose()}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </DialogTitle>
                )}
                {!showSubmit && (
                  <DialogContent sx={{
                    px: { xs: 0.8, sm: 0, md: 0 },
                    pb: { xs: 0, sm: 0, md: 0 }
                  }}>
                    {data.wp.companyDetails.companyDetails.newsletterPopup.text && (

                      <Typography
                        variant="body1"
                        component="div"
                        color="tertiary.main"
                        dangerouslySetInnerHTML={{
                          __html:
                            data.wp.companyDetails.companyDetails.newsletterPopup
                              .text,
                        }}
                        sx={{
                          fontSize: { xs: `${theme.spacing(0.5)} !important`, md: 'unset' },
                          lineHeight: { xs: `${theme.spacing(0.9)} !important`, md: 'unset' },
                        }}
                      />
                    )
                    }
                    <Grid container spacing={{ xs: 0, md: 1 }}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="filled"
                          color="secondary"
                          margin="dense"
                          id="fieldikikduu"
                          name={cmFormValues.firstName}
                          label="First Name"
                          InputLabelProps={{
                            sx: {
                              fontSize: { xs: theme.spacing(0.5), md: theme.spacing(0.5) }
                            }
                          }}
                          type="text"
                          required
                          fullWidth
                          aria-label="First Name"
                          maxLength="200"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              pt: { xs: theme.spacing(0), md: 'unset' },
                              pb: { xs: theme.spacing(0), md: 'unset' }
                            }
                          }}
                          sx={{
                            my: { xs: `${theme.spacing(0.1)} !important`, md: 'unset' },
                            "& .MuiInputBase-input": {
                              pt: { xs: `${theme.spacing(0.5)} !important`, md: `${theme.spacing(0.5)} !important` },
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          variant="filled"
                          color="secondary"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              pt: { xs: theme.spacing(0), sm: 0, md: 'unset' },
                              pb: { xs: theme.spacing(0), sm: 0, md: 'unset' }
                            }
                          }}
                          margin="dense"
                          label="Last Name"
                          InputLabelProps={{
                            sx: {
                              fontSize: { xs: theme.spacing(0.5), md: theme.spacing(0.5) }
                            }
                          }}
                          aria-label="Last Name"
                          id="fieldikikhll"
                          maxLength="200"
                          name={cmFormValues.lastName}
                          type="text"
                          required
                          fullWidth
                          sx={{
                            my: { xs: `${theme.spacing(0.1)} !important`, md: 'unset' },
                            "& .MuiInputBase-input": {
                              pt: { xs: `${theme.spacing(0.5)} !important`, md: `${theme.spacing(0.5)} !important` },
                            }
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <TextField
                          variant="filled"
                          color="secondary"
                          InputProps={{
                            disableUnderline: true,
                            sx: {
                              pt: { xs: `${theme.spacing(0)} !important`, md: 'unset' },
                              pb: { xs: `${theme.spacing(0)} !important`, md: 'unset' }
                            }
                          }}
                          margin="dense"
                          autoComplete="Email"
                          aria-label="Email"
                          id="fieldEmail"
                          maxLength="200"
                          name={cmFormValues.email}
                          type="email"
                          label="Email Address"
                          InputLabelProps={{
                            sx: {
                              fontSize: { xs: theme.spacing(0.5), md: theme.spacing(0.5) }
                            }
                          }}
                          required
                          fullWidth
                          inputProps={inputProps}
                          sx={{
                            my: { xs: `${theme.spacing(0.1)} !important`, md: 'unset' },
                            "& .MuiInputBase-input": {
                              pt: { xs: `${theme.spacing(0.5)} !important`, md: `${theme.spacing(0.5)} !important` },
                            }
                          }}
                        />
                      </Grid>
                    </Grid>

                    <input
                      id="cm-privacy-consent-hidden"
                      name="cm-privacy-consent-hidden"
                      type="hidden"
                      value="true"
                    />
                  </DialogContent>
                )}
                {showSubmit && (
                  <Grid container columnSpacing={{ xs: 0, md: 1 }} rowSpacing={{ xs: 0, md: 1 }}>
                    <Grid item xs={12} md={12}>
                      <Box sx={{
                        pb: { xs: 2, md: 6 },
                        mx: { xs: 0, md: 6 },
                        py: { xs: '0 !important', md: 'unset' }
                      }}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" width={1} >
                          <Typography
                            variant="h2"
                            component="h3"
                            className="font-script"
                            color="primary"
                            align="center">
                            Thanks for signing up.
                          </Typography>
                        </Box>
                        <Box sx={{ mt: { xs: 2, md: 2 } }}>
                          <Typography variant="subtitle"
                            component="p" align="center">The Cornish Holiday Cottages Team</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {!showSubmit && (
                  <DialogActions sx={{
                    px: { xs: 0.8, sm: 0, md: 0 }
                  }}>

                    <Box px={{ xs: 0, md: 0 }} style={{ width: "100%" }}>
                      <Grid container rowSpacing={{ xs: 0.5, md: 1 }} columnSpacing={{ xs: 0, md: 1 }}>
                        <Grid item xs={12} md={6}>
                          <Box
                            display="flex"
                            alignItems="center"
                            style={{ height: "100%" }}
                          >
                            {data.wp.companyDetails.companyDetails.newsletterPopup
                              .policyLink && (
                                <Link
                                  sx={{
                                    fontSize: { xs: theme.spacing(0.5), md: `${theme.spacing(0.5)} !important` },
                                  }}
                                  target={
                                    data.wp.companyDetails.companyDetails.newsletterPopup
                                      .policyLink.target
                                  }
                                  to={
                                    data.wp.companyDetails.companyDetails.newsletterPopup
                                      .policyLink.url
                                  }
                                >
                                  {
                                    data.wp.companyDetails.companyDetails.newsletterPopup
                                      .policyLink.title
                                  }
                                </Link>
                              )}
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            style={{ width: "100%" }}
                          >
                            Subscribe &amp; Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogActions>
                )}
              </Box>
            </form>
          </Dialog>
        </Box>)}
    </Box>
  )
}

export default NewsletterPopUp