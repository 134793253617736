import { ApolloClient, InMemoryCache } from '@apollo/client'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { AccountsClient } from '@accounts/client'
import { AccountsGraphQLClient } from '@accounts/graphql-client'
import { AccountsClientPassword } from '@accounts/client-password'
import { accountsLink } from '@accounts/apollo-link'
import fetch from "isomorphic-fetch"

const httpLink = new HttpLink({
  uri: process.env.GATSBY_GRAPH_ADDR + "/graphql" || "https://chc-graph.gendall.io",
  credentials: 'same-origin',
  fetch
})

const cache = new InMemoryCache()

const accountsApolloClient = new ApolloClient({
  link: ApolloLink.from([httpLink]),
  cache,
})

const accountsTransport = new AccountsGraphQLClient({
  graphQLClient: accountsApolloClient,
})

export const client = new AccountsClient({}, accountsTransport)

export const accounts = new AccountsClientPassword(client)

const authLink = accountsLink(() => client)

const errorHandling = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
})

export const apollo = new ApolloClient({
  link: ApolloLink.from([
    errorHandling,
    authLink,
    httpLink
  ]),
  cache
})
