import React, { createContext, useState, useEffect } from "react"
import { string, object, array, number } from 'yup'

const PartyContext = createContext()

const PartyProvider = props => {

  const memberSchema = object({
    name: string(),
    age: number().integer().min(0).nullable(),
  })

  // TODO:  for the booking the party needs to be array[{}]

  const schema = object({
    adults: array().of(
      memberSchema
    ),
    children: array().of(
      memberSchema
    )
  });

  // ! Simplify the context

  const storage = typeof sessionStorage !== `undefined` ? sessionStorage : {}

  const initAdults = storage.adultPartyState && JSON.parse(storage.adultPartyState)
  const initChildren = storage.childrenPartyState && JSON.parse(storage.childrenPartyState)

  const adultSchema = object({
    adults: array().of(
      memberSchema
    )
  });

  const [adultParty, updateAdultParty] = useState(adultSchema.isValidSync(initAdults)
    ? adultSchema.cast(initAdults)
    : { adults: [] }
  )

  const setAdultParty = data => adultSchema.validate(data).then(result => updateAdultParty(result))
  const resetAdultParty = () => setAdultParty()

  const childrenSchema = object({
    children: array().of(
      memberSchema
    )
  });

  const [childrenParty, updateChildrenParty] = useState(childrenSchema.isValidSync(initChildren)
    ? childrenSchema.cast(initChildren)
    : { children: [] }
  )

  const setChildrenParty = data => childrenSchema.validate(data).then(result => updateChildrenParty(result))
  const resetChildrenParty = () => setChildrenParty()

  // Anytime Reponse Schema

  const atPartyInit = storage.partyMemberIdState && JSON.parse(storage.partyMemberIdState)

  const atMemberSchema = object({
    description: string(),
    id: number().integer().required(),
    max_number: number().integer().required(),
    name: string(),
  })

  const atSchema = object({
    anyTime: array().of(
      atMemberSchema
    )
  });

  const [partyMemberIds, updatePartyMemberIds] = useState(atSchema.isValidSync(atPartyInit) ? atSchema.cast(atPartyInit) : [])

  const setPartyMemberIds = data => updatePartyMemberIds(data)


  // Old
  const init = storage.partyState && JSON.parse(storage.partyState)

  const [party, updateParty] = useState(schema.isValidSync(init)
    ? schema.cast(init)
    : []
  )

  const setParty = data => schema.validate(data).then(result => updateParty(result))
  const resetParty = () => setParty()

  const store = {
    party,
    setParty,
    resetParty,
    partyMemberIds,
    setPartyMemberIds,

    adultParty,
    setAdultParty,
    resetAdultParty,
    childrenParty,
    setChildrenParty,
    resetChildrenParty,
  }

  useEffect(() => {
    storage.setItem('partyState', JSON.stringify(party))
    storage.setItem('adultPartyState', JSON.stringify(adultParty))
    storage.setItem('childrenPartyState', JSON.stringify(childrenParty))
    storage.setItem('partyMemberIdState', JSON.stringify(partyMemberIds))
  })

  return <PartyContext.Provider value={store} {...props} />
}

export { PartyProvider as default, PartyContext }
