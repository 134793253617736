import mq from "../../mediaQueries"

// padding: [0, 0, rhythm(0.5), 0].join(" "),
// [mq.minWidths.xl]: {

const headerStyles = {
  bottomMenuWrapper: {},
  headerWrapper: {
    display: "flex",
    flexDirection: "column",

    [mq.minWidths.lg]: {
      // display: "grid",
      // gridTemplateColumns: "1fr 1fr",
      // gridTemplateRows: "1fr 1fr",
      //gridTemplateAreas: "myArea",
    },
    menuButton: {
      // gridColumnStart: 1,
      // gridColumnEnd: 2,
      // gridRowStart: 1,
      // gridRowEnd: 1,

      closeMenu: {},
    },
  },
  backgroundColor: "green",
}

export default headerStyles
